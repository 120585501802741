/* Footer.module.css */

.footer {
  background-color: #1e1e1e;
  color: #fff;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #333;
}

.footerContent {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact {
  margin-top: 10px;
}

.contact a {
  color: #ff9900;
  text-decoration: none;
  font-weight: bold;
}

.contact a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .footerContent {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .contact {
    margin-top: 0;
  }
}
