.tool-content {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  overflow: hidden;
}

.toolbar {
  display: flex;
}

.toolbar button {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  padding: 0.5em;
  font-size: 1.2em;
}

.toolbar button:hover {
  color: #aaa;
}

.toolbar button[title] {
  position: relative;
}

.toolbar button[title]:hover::after {
  content: attr(title);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 0.25em 0.5em;
  border-radius: 0.25em;
  white-space: nowrap;
  font-size: 0.75em;
}

.editor-container {
  flex: 1;
  overflow: auto; /* Enable vertical scrolling */
}

.editor-card {
  max-height: 575px;
  overflow: auto;
}


.tool-error {
  color: red;
  background-color: #253238;
  border: 1px solid #f99;
  padding: 0.5em;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.5);
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
