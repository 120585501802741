/* Base64ImageEncoderDecoder.module.css */

.tool-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.input-section,
.output-section {
  width: 48%;
}

.input-actions,
.image-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.input-section textarea {
  height: 575px;
}

.image-container {
  width: 100%;
  height: 575px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.tool-error {
  color: red;
  margin-top: 10px;
}
