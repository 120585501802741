/* Header.module.css */

.header {
  background-color: #333;
  padding: .6% 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.menuContainer {
  display: flex;
  align-items: center; /* Align items vertically within menuContainer */
}

.companyName {
  display: flex;
  align-items: center;
}

.logo {
  width: 50px;
  height: 50px;
  margin-right: 10px; /* Adjust margin as needed */
  border-radius: 25px;
}

.companyText {
  font-size: 1.5em;
  color: #fff;
  font-weight: bold;
}

.menu {
  margin-left: 1em; /* Adjust margin as needed */
  color: #fff;
  cursor: pointer;
}
