/* Reset body and html styles */
body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Arial, sans-serif;
    background-color: #050505;
    color: #fff;
  }
  
  /* Container for the main content */
  .tool-content {
    max-width: 90vw;
    background: #000000;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    height: 70vh; /* Adjust height as needed */
    display: flex;
    flex-direction: column;
  }
  
  /* Styling for input section */
  .input-section {
    display: flex;
    justify-content: space-between;
  }
  
  .text-area {
    width: 48%;
    padding: 10px;
    font-size: 16px;
    color: #fff;
    border-radius: 8px;
  }
  
  /* Styling for result section */
  .result-section {
    background-color: #000;
    padding: 1em;
    border-radius: 10px;
  }
  
  .result-text {
    background-color: #263238;
    padding: 10px;
    border-radius: 10px;
    white-space: pre-wrap;
    overflow: auto;
    height: 320px;
  }
  
  .added {
    background-color: green;
    color: white;
  }
  
  .removed {
    background-color: red;
    color: white;
  }
  
  .result-section button {
    margin-top: 10px;
    padding: 5px 10px;
    font-size: 1em;
    cursor: pointer;
    border: 1px solid #333;
    border-radius: 4px;
    background: #000;
    color: #fff;
  }
  
  .result-section button:hover {
    background: #555;
  }
  