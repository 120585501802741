/* global.css */

body {
  background-color: #000;
  color: #fff;
  margin: 0;
  font-family: Arial, sans-serif;
}

button {
  padding: 0.5em;
  background-color: #f90;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

button:hover {
  background-color: #555;
}

pre {
  background-color: #111;
  padding: 1em;
  white-space: pre-wrap;
}

a{
  color:white;
  text-decoration: none;
}

textarea {
  width: 100%;
  height: 200px;
  padding: 10px;
  box-sizing: border-box;
  resize: none;
  background-color: #263238;
  color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.error {
  border-color: red;
}
