body {
  background-color: #000;
  color: #fff;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

#hero {
  background: linear-gradient(to right,#253238, #94989e);
  padding: 60px 20px;
  text-align: center;
  color: #fff;
}

.hero-content h1 {
  font-size: 2.8em;
  margin-bottom: 20px;
}

.hero-content p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.cta-button {
  background-color: #ff9900;
  color: #fff;
  padding: 12px 24px;
  text-decoration: none;
  border-radius: 5px;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #e88e00;
}

section {
  padding: 50px 20px;
}

h2 {
  font-size: 2.2em;
  margin-bottom: 20px;
  text-align: center;
}

.tools-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.tool-card {
  background-color: #253238;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.tool-card:hover {
  transform: translateY(-5px);
}

.tool-card h3 {
  font-size: 1.6em;
  margin-bottom: 10px;
}

.tool-link {
  background-color: #ff9900;
  color: #fff;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  display: inline-block;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.tool-link:hover {
  background-color: #005bb5;
}

.testimonials-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.testimonial {
  background-color: #253238;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 600px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.testimonial p {
  margin: 10px 0;
}

#contact a {
  color: #ff9900;
  text-decoration: none;
}

#contact a:hover {
  text-decoration: underline;
}
