/* Set html and body to 100% height */
html, body {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
  height: 100%; /* Ensure app takes full height */
}

.header, .footer {
  flex-shrink: 0;
}

.mainContent {
  display: flex;
  flex: 1;
  overflow: hidden;
  height: 100%; /* Ensure mainContent takes full height */
}

.toolCard {
  display: flex;
  flex: 0 0 auto;
  max-width: 26%;
  transition: width 0.3s ease;
  padding: 1em;
}

.toolPlayground {
  display: flex;
  flex: 1 1 auto;
  padding: 1em 1em 1em 0em;
  transition: margin-left 0.3s ease;
  overflow: hidden;
}
