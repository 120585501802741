/* Base64StringEncoderDecoder.module.css */

.tool-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-section {
  width: 100%;
  margin-bottom: 20px;
}

.input-actions {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.radio-buttons {
  display: flex;
  align-items: center;
}

.radio-buttons label {
  margin-right: 20px; /* Increased margin between radio buttons */
}

textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Adjusted font size */
  border: 1px solid #ced4da; /* Light border */
  border-radius: 5px;
  resize: none; /* Prevent textarea resize */
}

textarea.error {
  border-color: red; /* Red border on error */
}

.output-section {
  width: 100%;
  padding: 10px;
}

.output-section textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px; /* Adjusted font size */
  border: 1px solid #ced4da; /* Light border */
  border-radius: 5px;
  resize: vertical; /* Allow vertical resize */
  margin-bottom: 10px;
}

.tool-error {
  width: 100%;
  margin-top: 10px;
  padding: 10px;
  background-color: #f44336; /* Red error background */
  color: #fff;
  border-radius: 5px;
  text-align: center;
}
