.tool-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 1em;
  min-width: 50px;
  background-color: #222;
  border-radius: 5px;
  overflow-y: auto;
  position: relative;
  transition: width 0.3s ease, padding 0.3s ease;
  width: auto;
  box-sizing: border-box;
}

.expanded {
  min-width: 250px;
  padding: 1em 2em;
}

.toolbar {
  display: flex;
  align-items: center;
  padding: 0.5em;
  background-color: #111; /* Updated background color */
  border-radius: 5px; /* Added border radius */
  margin-bottom: 1em;
}

.search-icon {
  color: #fff;
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.search-bar {
  flex: 1;
  padding: 0.5em;
  background-color: #111;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: width 0.3s ease, opacity 0.3s ease, visibility 0.3s ease;
}

.input-visible {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.input-hidden {
  width: 0;
  opacity: 0;
  visibility: hidden;
}

.tool-list {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0.5em;
  background-color: #111;
  border-radius: 5px;
}

.tool-item {
  padding: 0.5em;
  cursor: pointer;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}

.tool-icon {
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  margin-right: 0.5em;
}

.tool-name {
  margin-left: 0.5em;
  display: inline-block;
  transition: opacity 0.3s ease, width 0.3s ease;
  opacity: 0;
  width: 0;
}

.expanded .tool-name {
  opacity: 1;
  width: auto;
  margin-left: 0.5em;
}
