.tool-playground {
  display: flex;
  flex-direction: column;
  width: 100%; /* Ensure it takes up full width of its parent */
  background-color: #222;
  padding: 1em;
  border-radius: 5px;
  overflow: hidden;
}

.tool-playground-header {
  display: flex;
  justify-content: center;
  background-color: #333;
  text-align: center;
  padding: 0.5em;
  border-radius: 5px;
  margin-bottom: 1em;
  color: white;
  width: 50%; /* Set width to 50% */
  margin: 0 auto; /* Center horizontally */
  margin-bottom: 1em;
}

.tool-content {
  flex: 1; /* This ensures it stretches to fill available space */
  overflow: hidden;
  background-color: #000;
  border-radius: 5px;
  padding: 1em;
}

.tool-not-found {
  color: white;
  text-align: center;
  font-size: 1.2em;
  margin-top: 1em;
}
