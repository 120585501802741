/* Reset body and html styles */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #050505;
  color: #fff;
}

/* Container for the main content */
.tool-content {
  max-width: 80vw;
  background: #000000;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 70vh; /* Adjust height as needed */
  display: flex; /* Ensure content is displayed in a flex container */
  flex-direction: column; /* Arrange content in a column */
}

/* Styling for input and timezone sections */
.input-section, .timezone-section {
  margin-bottom: 20px;
}

/* Styling for input type selector and actions */
.input-type-selector, .input-actions {
  margin-bottom: 10px;
}

.input-type-selector label {
  display: flex;
  align-items: center;
  font-weight: bold;
}

.input-type-selector select {
  margin-left: 10px;
  padding: 5px;
  font-size: 1em;
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  border: none;
}

.input-actions {
  display: flex;
  align-items: center;
  gap: 10px
}

.input-actions input {
  padding: 5px;
  font-size: 1em;
  margin-right: 15px;
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  border: none;
}

input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* .input-actions button {
  padding: 5px 10px;
  font-size: 1em;
  margin-right: 5px;
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 4px;
  background: #333;
  color: #fff;
} */

/* .input-actions button:hover {
  background: #555;
} */

/* Styling for date details section */
.date-details {
  background-color: #000;
  padding: 1em;
  border-radius: 10px;
}

.date-details-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px; /* Adjust spacing between items */
  width: 50%;
}

.label {
  flex: 0 0 auto; /* Ensures label doesn't grow */
  text-align: left; /* Left align the label */
  width: 35%; /* Adjust label width as needed */
  white-space: nowrap; /* Prevent text wrapping */
  margin-right: 10px; /* Add space between label and highlight */
}

.highlight {
  flex: 1; /* Takes remaining space */
  text-align: center; /* Center align the content in the span */
  background-color: #333;
  padding: 2px 4px;
  border-radius: 20px;
  width: auto; /* Adjust width as needed */
  white-space: nowrap; /* Prevent text wrapping */
  margin-left: 10px; /* Add space between highlight and button */
}

.date-details button {
  margin-left: 10px;
  padding: 3px 6px;
  font-size: 0.9em;
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 4px;
  background: #000;
  color: #fff;
}

.date-details button:hover {
  background: #555;
}

/* Styling for timezone section */
.timezone-section {
  display: flex;
  flex-direction: column;
}

.timezone-section label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.timezone-section input {
  padding: 5px;
  font-size: 1em;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 10px;
  width: auto; /* Adjust width automatically */
  margin-left: 10px;
}

.selected-timezones {
  margin-top: 10px;
  max-height: 150px;
  overflow-y: auto;
}

.timezone-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #333;
  border-radius: 10px;
  margin-bottom: 5px;
  background: #333;
  width: 50%;
  white-space: nowrap; /* Prevent text wrapping */
}

.timezone-row span {
  font-size: 1em;
  white-space: nowrap; /* Prevent text wrapping */
}

.timezone-row button {
  padding: 5px 10px;
  font-size: 1em;
  cursor: pointer;
  border: 1px solid #333;
  border-radius: 4px;
  background: #000;
  color: #fff;
}

.timezone-row button:hover {
  background: #555;
}
